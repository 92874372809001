.users-container {
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
}

.fieldset {
  display: flex;
  padding: 50px;
  border-bottom: 1px solid #dcdcdc;
  &__title_wrap {
    width: 30%;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    &__title_wrap {
      width: 100%;
    }
  }
}
.form-groups {
  display: flex;
  flex-direction: column;
  width: 70%;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.role-section {
  display: flex;
  flex-direction: column;
  width: 70%;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
}
.foundation-wrap {
  display: flex;
  padding: 50px;

  &__title_wrap {
    width: 30%;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    &__title_wrap {
      width: 100%;
    }
  }
}
.main-title {
  font-size: 25px;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
.action-btns {
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 20px;
  }
}
.last-fieldset {
  border: none !important;
}
