@use "@angular/material" as mat;
@import "./colors";
@import "./users-form";
@import '../../node_modules/jsoneditor/dist/jsoneditor.min.css';
@include mat.core();
:root {
  --color-primary: #084ed2;
  --color-accent: #d1e2ff;
  --color-primary-text: white;
  --color-warn: yellow;
  --danger: #f44336;
  --pending: orange;
  --success: seagreen;
  --info: #084ed2;
  --pale: #ebe6e6;
  --shadow: #313131;
  --black: #000000;
  --background: #fafafa;
}

/* :root {
 
} */
@font-face {
  font-family: Poppins;
  src: url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
}
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body main {
  margin: 16px 12px;
}

body main h1 {
  text-align: center;
}

body main button.major-action-button {
  min-width: 150px;
  min-height: 46px;
}

body main button.medium-action-button,
body button.medium-action-button {
  display: flex;
  min-width: 130px;
  min-height: 40px;

  @media screen and (max-width: 600px) {
    min-width: 120px;
    min-height: 40px;
  }
}

body main .mdc-evolution-chip__cell {
  display: flex;
  justify-content: center;
  text-align: center;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.mat-icon.mat-primary {
  --mat-icon-color: var(--color-primary);
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--color-primary);
}
.mat-step-icon-state-edit {
  background-color: var(--color-primary) !important;
  color: var(--color-primary-text);
}
.mat-step-icon-selected {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: var(--color-primary);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent,
.mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: var(--color-primary-text);
  --mdc-chip-elevated-container-color: var(--color-primary);
}
.snackbar--success div.mdc-snackbar__surface {
  background-color: var(--success);
  color: white;
}
.snackbar--error div.mdc-snackbar__surface {
  background-color: var(--danger);
  color: white;
}

.snackbar--default div.mdc-snackbar__surface {
  background-color: midnightblue;
  color: white;
}

.snackbar--success .mdc-button__label,
.snackbar--error .mdc-button__label,
.snackbar--default .mdc-button__label {
  color: white;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  caret-color: var(--color-primary);
}
.mat-focused .mat-mdc-form-field {
  color: var(--color-primary) !important;
}
.mat-focused .mat-form-field-label {
  color: var(--color-primary) !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: var(--color-primary) !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: var(--color-primary) !important;
}
.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(
    --color-primary
  ) !important;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(
    --color-primary
  ) !important;
}
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border-color: var(--color-primary) !important;
  background-color: var(--color-primary) !important;
}
.mat-calendar-body-selected {
  background-color: var(--color-primary) !important;
  color: var(--color-primary-text) !important;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: var(--color-primary-text) !important;
  --mdc-chip-elevated-container-color: var(--color-primary) !important;
  --mdc-chip-elevated-disabled-container-color: var(--color-primary) !important;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--color-primary) !important;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary {
  --mdc-chip-elevated-selected-container-color: var(--color-primary) !important;
}
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: var(--color-primary) !important;
}
.mat-mdc-tab-group {
  --mdc-tab-indicator-active-indicator-color: var(--color-primary);
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: var(--color-primary);
  --mat-tab-header-active-ripple-color: var(--color-primary);
  --mat-tab-header-inactive-ripple-color: var(--color-primary);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: var(--color-primary);
  --mat-tab-header-active-hover-label-text-color: var(--color-primary);
  --mat-tab-header-active-focus-indicator-color: var(--color-primary);
  --mat-tab-header-active-hover-indicator-color: var(--color-primary);
}
html,
body {
  height: 100%;
  --mdc-circular-progress-active-indicator-color: var(--color-primary);
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button {
  cursor: pointer;
}

.spinner-box {
  width: 100%;
  margin-top: 15%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20%;
}
